import { Box, CircularProgress } from '@mui/material';
import { Button, Chip, Grid, Icon, Modal, ModalActions, ModalTitle, ToastController, Typography, designTokens } from '@platform-ui/design-system';
import React, { Dispatch, FC, useState } from 'react';
import { Action, useStoreContext } from '../../../Store';
import Connect from "../../../Connect/Connect";
import { IADState } from '../state';
import { IADActionTypes } from '..';
import { buildWorkdayGlobalConstants } from '../../../Connectors/CustomTabs/Workday/utils';
import { buildWorkdayBillingGlobalConstants } from '../../../Connectors/CustomTabs/WorkdayBilling/utils';
import { buildSAPBillingGlobalConstants } from '../../../Connectors/CustomTabs/SAP/Billing/utils';
import { buildSAPRevenueGlobalConstants } from '../../../Connectors/CustomTabs/SAP/Revenue/utils';

interface ButtonGroupProps {
  showUnsavedChangesChip: boolean;
}

function objectIncludes(obj, target): boolean {
  // NOTE(Michae): back compat for proxy calls that didn't have target defined
  if (obj === undefined || target === undefined) {
    return true;
  }
  return Object.keys(target).every((key) => obj.hasOwnProperty(key) && obj[key] === target[key]);
}
// NOTE(Michael): this is to protect against double strings from mustache rendering double strings
function fuzzyJsonParsing(mustache_json_string) {
  if (mustache_json_string.startsWith("\"") || mustache_json_string.startsWith('\'')) {
    return JSON.parse(mustache_json_string.slice(1, -1));
  }
  return JSON.parse(mustache_json_string);
}

const NON_PERSISTENT_ITEMS = ['execution', 'executions', 'solutionInstances'];

export const ButtonGroup: FC<ButtonGroupProps> = ({
  showUnsavedChangesChip = false
}: ButtonGroupProps) => {
  const { state, dispatch } = useStoreContext() as { state: IADState, dispatch: Dispatch<Action> };
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState({
    show: false,
    msg: '',
    severity: 'success'
  });
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const connect: Connect = (window as any).connect;
  const isLocal = window.location.host.includes('localhost');
  const workflowProxyPath = `${isLocal ? 'http://localhost:8080' : ''}/platform/gateway-proxy-v2`;

  return (
    <>
      <Modal
        id='cancel-button-group-modal'
        open={openCancelModal}
        header={
          <ModalTitle
            icon={<Icon color='warning' body='warning' />}
            dsOnClose={() => setOpenCancelModal(false)}
          >
            Discard Your Changes?
          </ModalTitle>
        }
        body={
          <Typography>Are you sure you want to discard all the changes and go back to Integration Apps?</Typography>
        }
        dsOnClose={() => setOpenCancelModal(false)}
        footer={
          <ModalActions>
            <Button variant='outlined' dsOnClick={() => setOpenCancelModal(false)}>No</Button>
            <Button
              dsOnClick={() => {
                dispatch({type: IADActionTypes.IAD_CANCEL_BTN_CLICK});
                setOpenCancelModal(false);

                const connectId = (connect.tenant as any).connect_id;
                const origin = Connect.path();  // Determine the path prefix for redirection
                const path = window.location.pathname.includes('admin') ?
                  `/admin/integration_apps/${connectId}/integration_apps` :
                  `/integration_apps/${connectId}/integration_apps`;
                window.location.href = origin + path
              }}
            >
              Yes
            </Button>
          </ModalActions>
        }
      />
      { toast.show && <ToastController severity={toast.severity as any} message={toast.msg} /> }
      <Grid container item xs alignContent='flex-start' alignItems='center' justify='flex-end'>
        <Grid item>
          {showUnsavedChangesChip && <Chip size='medium' label='Unsaved Changes' state='warning' />}
        </Grid>
        <Grid item><Button variant='outlined' dsOnClick={() => setOpenCancelModal(true)}>Cancel</Button></Grid>
        <Grid item>
          <Box sx={{m: 1, position: 'relative'}}>
            <Button
              disabled={loading || !state.modified}
              dsOnClick={async () => {
                // Note(Duc): Enforce frontend validation before sending to backend
                try {
                  // Retrieve what stored in settings hash and validate them
                  const settingsHashKeys = Object.keys(state.settingsHash || {});
            
                  if (state.tabDetails !== undefined && state.tabDetails.length > 0) {
                    for (let i = 0; i < state.tabDetails.length; i++) {
                      const tab = state.tabDetails[i];
                      const keys = Object.keys(state.tabDetails[i] || {});
                      for (let j = 0; j < keys.length; j++) {
                        const settingsHashKey = keys[j]?.split('/').pop();
                        // check if this tab needs to be validated
                        if (!settingsHashKeys.includes(settingsHashKey)) {
                          continue;
                        }
                        const fieldGroups = tab[keys[j]]?.fieldGroups || [];
                        for (let k = 0; k < fieldGroups.length; k++) {
                          const fields = fieldGroups[k]?.fields || [];
                          for (let l = 0; l < fields.length; l++) {
                            const field = fields[l] || {};
                            if (field.required && (state.settingsHash[settingsHashKey]?.[field.name] == null || state.settingsHash[settingsHashKey]?.[field.name] === '')) {
                              return alert(`${field.titleTranslations?.en || field.name} is REQUIRED!!!`);
                            }
                          }
                        }
                      }
                    }
                  }
                } catch (err) {
                  Connect.log('Failed to validate required input fields: ', err); 
                }

                // change settingsHash to settings_hash so the backend could understand it
                const newState = {
                  ...state,
                  settings_hash: {
                    ...state.settingsHash,
                    fieldMapping: {
                      ...state.settingsHash['fieldMapping'],
                      mappingOptions: {}
                    }
                  }
                };

                delete newState.settingsHash;
                delete newState.settings_hash['filteredFieldMapping'];
                
                // remove nonpersistent items from settingsHash
                NON_PERSISTENT_ITEMS.forEach(tab => {
                  if (state.inbound && tab === 'solutionInstances') return;
                  delete newState.settings_hash[tab]
                });

                if (!loading) {
                  setLoading(true);
                  setToast({...toast, show: false});

                  try {
                    const csrf = document.querySelector('meta[name=\'csrf-token\']').getAttribute('content');
                    const options = {
                        method: state.createOrUpdate === 'update' ? 'PUT' : state.saved ? 'PUT' : 'POST',
                        headers: new Headers({
                            'Accept': 'application/json',
                            'Content-type': 'application/json',
                            'X-CSRF-Token': csrf
                        }),
                        body: JSON.stringify(newState)
                    };
                    const queryParams = state.createOrUpdate === 'update' && window.location.search === '?new=true' ? `?external_id=${newState.external_id}` : window.location.search;
                    const response = await window.fetch(`${state.settingsUrl}${queryParams}`, options);
                    const data = await response.json();
                    if (!response.ok) throw Error(data['message'] || 'Failed to save settings!');
                    if ("proxy_call" in data) {
                      for (let call of data["proxy_call"]) {
                        // Note(Duc): 02/28/2024 - skip setupSfdcCredential if oauth is selected for Salesforce CRM
                        if (call.path === '/apps/v1/setupSfdcCredential' && newState.settings_hash.authentication?.ZDPAuthSelection === 'oauth') {
                          continue;
                        }
                        let headers = fuzzyJsonParsing(call["headers"]);
                        let body = call["body"];
                        let proxied_response;

                        if (headers["Content-Type"] === "application/json") {
                          proxied_response = await Connect.proxyCall(call["path"], call["method"], fuzzyJsonParsing(body), headers);
                        } else if (headers["Content-Type"] === "application/x-www-form-urlencoded") {
                          // Convert the URL-encoded form string to an object
                          let formData = new URLSearchParams(body);
                          proxied_response = await Connect.proxyCall(call["path"], call["method"], formData, headers);
                        } else {
                          throw new Error("Unsupported Content-Type: " + headers["Content-Type"]);
                        }

                        const response_contentType = proxied_response.headers.get("content-type");

                        if (response_contentType) {
                          if (response_contentType.includes("application/json")) {
                              let proxied_data = await proxied_response.json();
                              if (!proxied_response.ok || !objectIncludes(proxied_data, call["expected_json_response"])) throw Error( proxied_data[call["expected_message_key"]] ||'Failed to save settings!');
                          } else if (response_contentType.includes("text/html")) {
                              let expected_html_response = call["expected_html_response"];
                              let proxied_html = await proxied_response.text();
                              if (!proxied_response.ok || !proxied_html.includes(expected_html_response)) throw Error('Failed to save settings!');
                              console.log(proxied_html);
                          } else {
                              throw new Error("Unsupported Content-Type in response: " + response_contentType);
                          }
                        } else {
                          if(!proxied_response.ok) {
                            throw new Error('Request was not successful. Status: ' + proxied_response.status);
                          }
                        }
                      }
                    }
                    if (state.workflow_template?.['workflow_definition']?.['name'] === 'z-IntegrationHub_Workday_IH_Internal_Workday_Outbound') {
                      const response = await Connect.proxyCall(
                        `${workflowProxyPath}/workflows/global_constants`,
                        'PUT',
                        buildWorkdayGlobalConstants(state.settingsHash['authentication'] || {}, state.settingsHash['emailNotifications'] || {}, state.settingsHash['fieldMapping'] || {}),
                        { 'Content-Type': 'application/json', 'Zuora-Tenant-Id': (connect.tenant as any).tenant_id, 'Scope': 'Internal' }
                      );
                      if (!response.ok) throw Error(response.statusText);
                    }
                    if (state.workflow_template_name === 'z-IntegrationHub-WorkdayBilling') {
                      const response = await Connect.proxyCall(
                        `${workflowProxyPath}/workflows/global_constants`,
                        'PUT',
                        buildWorkdayBillingGlobalConstants(state.settingsHash['authentication'] || {}, state.settingsHash['emailNotifications'] || {}, state.settingsHash['fieldMapping'] || {}, state.envRestEndpoint),
                        { 'Content-Type': 'application/json', 'Zuora-Tenant-Id': (connect.tenant as any).tenant_id, 'Scope': 'Internal' }
                      );
                      if (!response.ok) throw Error(response.statusText);
                    }
                    if (state.workflow_template?.['workflow_definition']?.['name'] === 'z-IntegrationHub-SAP-Billing') {
                      const response = await Connect.proxyCall(
                        `${workflowProxyPath}/workflows/global_constants`,
                        'PUT',
                        buildSAPBillingGlobalConstants(
                          state.settingsHash['authentication'] || {},
                          state.settingsHash['emailNotifications'] || {},
                          state.settingsHash['fieldMapping'] || {},
                          state.envRestEndpoint,
                          state.settingsHash['destination'] || []
                        ),
                        { 'Content-Type': 'application/json', 'Zuora-Tenant-Id': (connect.tenant as any).tenant_id, 'Scope': 'Internal' }
                      );
                      if (!response.ok) throw Error(response.statusText);
                    }
                    if (state.workflow_template?.['workflow_definition']?.['name'] === 'z-IntegrationHub-SAP-Revenue') {
                      const response = await Connect.proxyCall(
                        `${workflowProxyPath}/workflows/global_constants`,
                        'PUT',
                        buildSAPRevenueGlobalConstants(
                          state.settingsHash['authentication'] || {},
                          state.settingsHash['emailNotifications'] || {},
                          state.settingsHash['fieldMapping'] || {},
                          state.settingsHash['destination'] || []
                        ),
                        { 'Content-Type': 'application/json', 'Zuora-Tenant-Id': (connect.tenant as any).tenant_id, 'Scope': 'Internal' }
                      );
                      if (!response.ok) throw Error(response.statusText);
                    }
                    setLoading(false);
                    setToast({
                      show: true,
                      msg: 'Settings were saved successfully.',
                      severity: 'success'
                    });
                    dispatch({
                      type: IADActionTypes.IAD_SAVE_BTN_CLICK
                    });
                  } catch (error) {
                    Connect.log(error);
                    setLoading(false);
                    setToast({
                      show: true,
                      msg: error.message,
                      severity: 'error'
                    });
                  }
                }
              }}
            >
              Save
            </Button>
            {
              loading &&
              <CircularProgress
                size={24}
                sx={{
                  color: designTokens.colors.blue500,
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px'
                }}
              />
            }
          </Box>
        </Grid>
      </Grid>
    </>
  );
};