import {Backdrop, CircularProgress, FormControlLabel, Switch} from '@mui/material';
import {
  Button,
  Checkbox,
  FormGroup,
  FormLabel,
  Grid, Modal, ModalActions,
  ModalTitle, TextField, ToastController,
  Typography
} from '@platform-ui/design-system';
import React, { Dispatch, useState } from 'react';
import { IADState } from '../../../IntegrationApps/IntegrationAppDetails/state';
import { Action, useStoreContext } from '../../../Store';
import { IADActionTypes } from '../../../IntegrationApps/IntegrationAppDetails';
import {
  SAP_CHANGE_EMAIL_NOTIFICATIONS_ENABLED,
  SAP_CHANGE_EMAIL_NOTIFICATIONS_FAILURES,
  SAP_CHANGE_EMAIL_NOTIFICATIONS_WARNINGS,
  SAP_CHANGE_EMAIL_NOTIFICATIONS_SUCCESS,
  SAP_EMAIL_NOTIFICATIONS_ADD_ADDRESS,
  SAP_EMAIL_NOTIFICATIONS_CANCEL_BUTTON_CLICK,
  SAP_EMAIL_NOTIFICATIONS_DELETE_ADDRESS,
  SAP_EMAIL_NOTIFICATIONS_SAVE_BUTTON_CLICK,
  SAP_EMAIL_NOTIFICATIONS_UPDATE_ADDRESS
} from "./action_types";
import Connect from "../../../Connect/Connect";
import {IAD_SAVE_BTN_CLICK} from "../../../IntegrationApps/IntegrationAppDetails/action_types";
import { buildSAPBillingGlobalConstants } from './Billing/utils';
import { buildSAPRevenueGlobalConstants } from './Revenue/utils';

const NON_PERSISTENT_ITEMS = ['execution', 'executions', 'solutionInstances'];
const SUCCESSFUL_SAVE_MESSAGE = 'Email configurations were saved successfully.';

export const AuthHeaderAction = ({defaultWorkflowName, system}) => {
  const {state, dispatch} = useStoreContext() as {state: IADState, dispatch: Dispatch<Action>};
  const [checked, setChecked] = useState(!!state.active);
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [numberOfInvalidEmails, setNumberOfInvalidEmails] = useState(0);
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState({
    show: false,
    msg: '',
    severity: 'success'
  });
  const connect: Connect = (window as any).connect;
  const isLocal = window.location.host.includes('localhost');
  const workflowProxyPath = `${isLocal ? 'http://localhost:8080' : ''}/platform/gateway-proxy-v2`;
  const workflowName = state.workflow_template?.workflow_definition?.name || defaultWorkflowName;
  const handleEnableIntegration = async (checked: boolean) => {
    setChecked(checked);
    dispatch({
      type: IADActionTypes.ENABLE_INTEGRATION,
      payload: checked
    });
    const defaultInstance = state.settingsHash['solutionInstances']?.find(s => s.solution_instance_name === workflowName);
    if (defaultInstance?.id) {
      try {
        const response = await Connect.proxyCall(
          `${workflowProxyPath}/workflows/${defaultInstance?.id}`,
          'PATCH',
          {
            active_workflow_version_id: defaultInstance?.active_version_id,
            status: checked ? 'Active' : 'Inactive'
          },
          { 'Content-Type': 'application/json', 'Zuora-Tenant-Id': (connect.tenant as any).tenant_id, 'Scope': 'Internal' }
        );
        if (!response.ok) throw Error(response.statusText);
      } catch (error) {
        Connect.log(`Failed to ${checked ? 'activate' : 'deactivate'} the Connector workflow for tenant ${(connect.tenant as any).tenant_id}: ${error}`);
      }
    }
  };
  const handleEmailNotificationsEnabled = (checked: boolean) => {
    dispatch({
      type: SAP_CHANGE_EMAIL_NOTIFICATIONS_ENABLED,
      payload: checked
    });
  }
  const handleFailuresSwitch = (checked: boolean) => {
    dispatch({
      type: SAP_CHANGE_EMAIL_NOTIFICATIONS_FAILURES,
      payload: checked
    });
  };
  const handleWarningsSwitch = (checked: boolean) => {
    dispatch({
      type: SAP_CHANGE_EMAIL_NOTIFICATIONS_WARNINGS,
      payload: checked
    });
  };
  const handleSuccessSwitch = (checked: boolean) => {
    dispatch({
      type: SAP_CHANGE_EMAIL_NOTIFICATIONS_SUCCESS,
      payload: checked
    });
  };
  const handleAddEmailAddress = () => {
    setNumberOfInvalidEmails(numberOfInvalidEmails + 1);
    dispatch({
      type: SAP_EMAIL_NOTIFICATIONS_ADD_ADDRESS,
    });
  };
  const handleUpdateEmailAddress = (index: number, email: string) => {
    let oldEmailAddress = state.settingsHash['emailNotifications']['emails'][index];
    let oldEmailWasInvalid = emailIsInvalid(oldEmailAddress);
    let newEmailIsInvalid = emailIsInvalid(email);
    if (oldEmailWasInvalid && !newEmailIsInvalid) {
      setNumberOfInvalidEmails(numberOfInvalidEmails - 1);
    } else if (!oldEmailWasInvalid && newEmailIsInvalid) {
      setNumberOfInvalidEmails(numberOfInvalidEmails + 1);
    }
    dispatch({
      type: SAP_EMAIL_NOTIFICATIONS_UPDATE_ADDRESS,
      payload: {
        index: index,
        email: email
      }
    });
  }
  const handleDeleteEmailAddress = (index: number) => {
    if (emailIsInvalid(state.settingsHash['emailNotifications']['emails'][index])) {
      setNumberOfInvalidEmails(numberOfInvalidEmails - 1);
    }
    dispatch({
      type: SAP_EMAIL_NOTIFICATIONS_DELETE_ADDRESS,
      payload: index
    });
  }
  const handleCancel = () => {
    setNumberOfInvalidEmails(0);
    setOpenEmailModal(false);
    dispatch({
      type: SAP_EMAIL_NOTIFICATIONS_CANCEL_BUTTON_CLICK
    });
  }
  const emailIsInvalid = (emailAddress: string) => {
    let regex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    return !regex.test(emailAddress);
  }

  return (
    <>
      <Modal
        id='email-notifications-modal'
        open={openEmailModal}
        disableBackdropClick={true}
        header={
          <ModalTitle
            dsOnClose={() => handleCancel()}
          >
            Email Configurations
          </ModalTitle>
        }
        dsOnClose={() => {
          setOpenEmailModal(false);
        }}
        body={
          <>
            <Grid>
              <Grid item><Typography variant='subtitle1'>Email settings:</Typography></Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      size='small'
                      checked={!!state.settingsHash['emailNotifications']?.['enabled']}
                      onChange={(_, emailNotificationsEnabled) => {
                        handleEmailNotificationsEnabled(emailNotificationsEnabled)
                      }}
                    />
                  }
                  label='Enable email notifications'
                />
              </Grid>
              {
                state.settingsHash['emailNotifications']?.['enabled'] &&
                  <Grid container direction='column'>
                      <Grid item>
                          <FormGroup>
                              <FormLabel>Emails to be sent for:</FormLabel>
                              <Checkbox
                                  dsOnChange={(_, emailFailuresChecked) => handleFailuresSwitch(emailFailuresChecked)}
                                  checked={!!state.settingsHash['emailNotifications']?.['failures']}
                                  label='Failures'
                              />
                              <Checkbox
                                  dsOnChange={(_, emailWarningsChecked) => handleWarningsSwitch(emailWarningsChecked)}
                                  checked={!!state.settingsHash['emailNotifications']?.['warnings']}
                                  label='Warnings'
                              />
                              <Checkbox
                                  dsOnChange={(_, emailSuccessChecked) => handleSuccessSwitch(emailSuccessChecked)}
                                  checked={!!state.settingsHash['emailNotifications']?.['success']}
                                  label='Success'
                              />
                          </FormGroup>
                      </Grid>
                      <Grid item>
                          <Grid container direction='column'>
                            {
                              (state.settingsHash['emailNotifications']?.['emails'] || []).map((item, idx) => (
                                <Grid item>
                                  <TextField error={emailIsInvalid(item)} value={item}
                                             dsOnChange={(event) => handleUpdateEmailAddress(idx, event.target.value)}/>
                                  <Button icon='delete' dsOnClick={() => handleDeleteEmailAddress(idx)}/>
                                </Grid>
                              ))
                            }
                          </Grid>
                      </Grid>
                      <Grid item>
                          <Button variant='text' startIcon='add' children='Add Email'
                                  dsOnClick={() => handleAddEmailAddress()}/>
                      </Grid>
                  </Grid>
              }
            </Grid>
            <Backdrop open={loading} invisible={true}>
              <CircularProgress />
            </Backdrop>
          </>
        }
        footer={(
          <>
            <ModalActions>
              <Button variant='outlined' dsOnClick={() => handleCancel()}>Cancel</Button>
              <Button disabled={!state.emailNotificationsModified || numberOfInvalidEmails > 0 || loading}
                      dsOnClick={async () => {
                        const newState = {
                          ...state,
                          settings_hash: {
                            ...state.settingsHash,
                            fieldMapping: {
                              ...state.settingsHash['fieldMapping'],
                              mappingOptions: {}
                            },
                            emailNotifications: {
                              ...state.settingsHash['emailNotifications']
                            }
                          }
                        }

                        delete newState.settingsHash;

                        NON_PERSISTENT_ITEMS.forEach(tab => delete newState.settings_hash[tab]);

                        if (!loading) {
                          setLoading(true);
                          setToast({...toast, show: false});

                          try {
                            const csrf = document.querySelector('meta[name=\'csrf-token\']').getAttribute('content');
                            const options = {
                              method: state.createOrUpdate === 'update' ? 'PUT' : 'POST',
                              headers: new Headers({
                                'Accept': 'application/json',
                                'Content-type': 'application/json',
                                'X-CSRF-Token': csrf
                              }),
                              body: JSON.stringify(newState)
                            };
                            const response = await window.fetch(state.settingsUrl, options);
                            const data = await response.json();
                            if (!response.ok) throw Error(data['message'] || 'Failed to save settings!');
                            if ("proxy_call" in data) {
                              for (let call of data["proxy_call"]) {
                                let proxied_response = await Connect.proxyCall(call["path"], call["method"], JSON.parse(call["body"]), JSON.parse(call["headers"]));
                                let proxied_data = await proxied_response.json();
                                if (!proxied_response.ok) throw Error(proxied_data['message'] || 'Failed to save settings!');
                              }
                            }
                            if (state.workflow_template_name === workflowName) {
                              const workflowGlobalConstants = system === 'billing'
                                ? buildSAPBillingGlobalConstants(
                                    state.settingsHash['authentication'] || {},
                                    state.settingsHash['emailNotifications'] || {},
                                    state.settingsHash['fieldMapping'] || {},
                                    state.envRestEndpoint,
                                    state.settingsHash['destination'] || []
                                  )
                                : buildSAPRevenueGlobalConstants(
                                    state.settingsHash['authentication'] || {},
                                    state.settingsHash['emailNotifications'] || {},
                                    state.settingsHash['fieldMapping'] || {},
                                    state.settingsHash['destination'] || []
                                  );
                              const response = await Connect.proxyCall(
                                `${workflowProxyPath}/workflows/global_constants`,
                                'PUT',
                                workflowGlobalConstants,
                                { 'Content-Type': 'application/json', 'Zuora-Tenant-Id': (connect.tenant as any).tenant_id, 'Scope': 'Internal' }
                              );
                              if (!response.ok) throw Error(response.statusText);
                            }
                            setLoading(false);
                            setToast({
                              show: true,
                              msg: SUCCESSFUL_SAVE_MESSAGE,
                              severity: 'success'
                            });
                            dispatch({
                              type: SAP_EMAIL_NOTIFICATIONS_SAVE_BUTTON_CLICK
                            });
                            dispatch({
                              type: IAD_SAVE_BTN_CLICK
                            });
                            setOpenEmailModal(false);
                          } catch (error) {
                            Connect.log(error);
                            setLoading(false);
                            setToast({
                              show: true,
                              msg: error.message,
                              severity: 'error'
                            });
                          }
                        }
                      }}>
                Save
              </Button>
            </ModalActions>
          </>
        )}
      />
      { toast.show && <ToastController severity={toast.severity as any} message={toast.msg} /> }
      <Grid container item xs='auto' alignContent='flex-start' alignItems='center' justify='flex-end'>
        <Grid item>
          <FormControlLabel
            style={{pointerEvents: 'none'}}
            label='Enable Integration'
            control={
              <Switch
                size='small'
                checked={checked}
                onChange={(_, checked) => handleEnableIntegration(checked)}
                style={{pointerEvents: 'auto'}}
              />
            }
          />
        </Grid>
        <Grid item>
          <Button dsOnClick={() => {
            setOpenEmailModal(true)
          }} icon='email' disabled={!state.active} />
        </Grid>
      </Grid>
    </>
  );
};