/**
 * Build Workflow global constants for Field Mappings
 *
 * @param {any} fieldMappings - Field mappings between source and target
 */
export const buildGlobalConstantsForFieldMappings = (fieldMappings: any) => {
  let mappings = {};
  const mappingKeys = Object.keys(fieldMappings);
  if (mappingKeys.length > 0) {
    for (let key of mappingKeys) {
      if (key.startsWith('z-')) {
        // filter out only mappings that have both source and target fields set to a value
        const rawMappings: any[] = (fieldMappings[key] || []).filter(rawMapping => !!rawMapping?.sourceField?.value && !!rawMapping?.targetField?.value);
        rawMappings.forEach(rawMapping => {
          const sourceValue = rawMapping?.sourceField?.value;
          const targetValue = rawMapping?.targetField?.value;
          if (sourceValue) {
            mappings[sourceValue] = targetValue;
          }
        });
      }
    }
  }
  return mappings;
}

/**
 * Build Workflow global constants for Email Configurations
 *
 * @param {{enabled: boolean, failures: boolean, success: boolean, emails: string[]}} emailNotifications - Email notification configurations
 */
export const buildGlobalConstantsForEmailConfigs = (emailNotifications: {
  enabled: boolean;
  failures: boolean;
  warnings: boolean;
  success: boolean;
  emails: string[];
}) => {
  let emailConfigs = {};
  if (emailNotifications.enabled) {
    emailConfigs = {
      'Failures': emailNotifications.failures || false,
      'Warnings': emailNotifications.warnings || false,
      'Success': emailNotifications.success || false,
      'Emails': emailNotifications.emails || []
    };
  }
  return emailConfigs;
}