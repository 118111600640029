import {Alert, Spinner} from '@platform-ui/design-system';
import React from 'react';
import { Authentication as BaseAuthentication } from '../../../../../IntegrationApps/IntegrationAppDetails/components/Authentication/Authentication';
import { HeaderTitle } from './HeaderTitle';
import { AuthHeaderAction } from '../../AuthHeaderAction';
import useProvisionWorkflow from '../../../../../IntegrationApps/IntegrationAppDetails/useProvisionWorkflow';
import { useIdentity } from '../../../../../IdentityProvider';
import { useFlipperHook } from '../../../../../Tables/helpers';
import {SAP_ADD_SOLUTION_INSTANCE, SAP_LOAD_SOLUTION_INSTANCES, SAP_PROVISION_SOLUTION_INSTANCE_ERROR} from "../../action_types";

export const Authentication = ({
  tabName = 'authentication',
  locale,
  fieldGroups,
  lastUpdatedBy,
  lastUpdatedDate
}) => {
  const [sapFeatureEnabled, sapFeatureLoading] = useFlipperHook('enable_sap_connector');
  const { hasPermission } = useIdentity();
  const isConnectorEnabled = sapFeatureEnabled || hasPermission('permission.ZuoraSAPConnector');
  const defaultWorkflowName = 'z-IntegrationHub-SAP-Billing';
  useProvisionWorkflow(isConnectorEnabled, defaultWorkflowName,
    SAP_ADD_SOLUTION_INSTANCE, SAP_LOAD_SOLUTION_INSTANCES, SAP_PROVISION_SOLUTION_INSTANCE_ERROR);

  if (!isConnectorEnabled) {
    if (sapFeatureLoading) {
      return <Spinner />;
    }

    return (
      <Alert open center variant='outlined' severity='warning' body='Missing required permission ZuoraSAPConnector' />
    );
  }

  const subheader = 'Last Updated By: ' + `${lastUpdatedBy ? lastUpdatedBy : 'N/A'}` + ' | Last Updated Date: ' + `${lastUpdatedDate ? lastUpdatedDate : 'N/A'}`;
  const headerTitle = React.createElement(
    HeaderTitle,
    {header: 'Status', subheader: subheader, showTestConnection: true, tabName: tabName}
  )

  const headerAction = React.createElement(
    AuthHeaderAction,
    {defaultWorkflowName: defaultWorkflowName, system: 'billing'}
  );

  return BaseAuthentication({
    tabName,
    locale,
    fieldGroups,
    lastUpdatedBy,
    lastUpdatedDate
  }, headerTitle, headerAction);
};