import { Alert, Spinner } from '@platform-ui/design-system';
import React from 'react';
import Connect from '../../../Connect/Connect';
import { type SolutionInstance, WorkflowExecution } from '../../../IntegrationApps/IntegrationAppDetails/components/WorkflowExecution';
import { useFlipperHook } from '../../../Tables/helpers';
import { useToast } from '../../../ToastProvider';
import { useStoreState } from '../../../Store';
import { IADState } from '../../../IntegrationApps/IntegrationAppDetails/state';
import { useIdentity } from '../../../IdentityProvider';

export const Execution = (props: { system: string }) => {
  const state = useStoreState() as IADState;
  const [sapFeatureEnabled, sapFeatureLoading] = useFlipperHook('enable_sap_connector');
  const { setToast } = useToast();
  const connect: Connect = (window as any).connect;
  const isLocal = window.location.host.includes('localhost');
  const workflowProxyPath = `${isLocal ? 'http://localhost:8080' : ''}/platform/gateway-proxy-v2`;
  const isBilling = props.system === 'Billing';
  const workflowTemplateName = isBilling ? 'z-IntegrationHub-SAP-Billing' : 'z-IntegrationHub-SAP-Revenue';
  const { hasPermission } = useIdentity();
  const isConnectorEnabled = hasPermission('permission.ZuoraSAPConnector');

  const onViewSummaryClickHandler = async (
    args: { row: any },
    utils?: {
      setSummaryModalData: React.Dispatch<React.SetStateAction<any>>,
      setOpenSummaryModal: React.Dispatch<React.SetStateAction<boolean>> 
    }
  ) => {
    const { row } = args;
    try {
      const response = await Connect.proxyCall(
        `${workflowProxyPath}/workflows/tasks?workflow_id=${row.id}&name=Get Sync Job Summary`,
        'GET',
        undefined,
        {
          'Zuora-Tenant-Id': (connect.tenant as any).tenant_id,
          'Scope': 'Internal'
        }
      );

      if (!response.ok) throw Error(response.statusText);

      const { data } = await response.json();
      
      if (!Array.isArray(data) || data.length === 0) throw Error('No summary was found. Please try again later.');

      const syncJobResult = data[0]?.['data']?.['syncJobResult'];
      let summary = null;

      if (Array.isArray(syncJobResult)) {
        if (syncJobResult.length === 0) throw Error('No summary was found. Please try again later.');
        summary = syncJobResult[syncJobResult.length - 1]?.result;
      } else {
        summary = syncJobResult?.result;
      }

      if (!summary) throw Error('No summary was found. Please try again later.');

      utils.setSummaryModalData(summary);
      utils.setOpenSummaryModal(true);
    } catch (err) {
      Connect.log(`Failed to retrieve SAP Billing summary: ${err}`);
      setToast({
        message: `Failed to retrieve summary: ${err.message}`,
        severity: 'error',
        keyRender: Date.now()
      });
    }
  };

  const download = (fileName: string, content: any) => {
    const a = document.createElement('a');
    a.href = URL.createObjectURL(new Blob([JSON.stringify(content, null, 2)], { type: 'application/json' }));
    a.download = fileName;
    a.click();

    URL.revokeObjectURL(a.href);
    a.remove();
  };

  const onDownloadClickHandler = async (args: { row: any }) => {
    const { row } = args;
    try {
      const response = await Connect.proxyCall(
        `${workflowProxyPath}/workflows/tasks?workflow_id=${row.id}&name=FileLink`,
        'GET',
        undefined,
        {
          'Zuora-Tenant-Id': (connect.tenant as any).tenant_id,
          'Scope': 'Internal'
        }
      );

      if (!response.ok) throw Error(response.statusText);

      const { data } = await response.json();
      
      if (!Array.isArray(data) || data.length === 0) throw Error('No output task was found');

      const fileName = `${row.solution_instance_name}_${row.workflow_run_number}_execution.json`;

      download(fileName, data[0]?.['data']?.['Revenue::GenerateSummary']?.['Summary'] || {});
    } catch (err) {
      Connect.log(`Failed to download SAP Revenue summary: ${err}`);
      setToast({
        message: `Failed to download: ${err.message}`,
        severity: 'error',
        keyRender: Date.now()
      });
    }
  };

  if (sapFeatureLoading) {
    return <Spinner />;
  }

  // block access to Execution tab if connector is not enabled
  if (!sapFeatureEnabled && !isConnectorEnabled) {
    return (
      <Alert open center variant='outlined' severity='warning' body={`Missing required permission 'permission.ZuoraSAPConnector'`} />
    );
  }

  // block access to Execution tab if integration is not enabled in Authentication tab
  if (!state.active) {
    return (
      <Alert center variant='outlined' severity='warning' body='You must enable integration in Authentication tab first' open={true} />
    );
  }

  // block access to Execution tab if credentials are not set in Authentication tab
  if (!state.settingsHash['authentication']) {
    return (
      <Alert center variant='outlined' severity='warning' body='You must configure Authentication and save first' open={true} />
    );
  }

  // block access to Execution tab if destinations are not set in Destination tab
  if (!Array.isArray(state.settingsHash['destination']) || state.settingsHash['destination'].length === 0) {
    return (
      <Alert center variant='outlined' severity='warning' body='You must configure Destination and save first' open={true} />
    );
  }

  // block access to Execution tab if field mappings are not set in Field Mapping tab
  if (!Array.isArray(state.settingsHash['fieldMapping']?.[workflowTemplateName]) || state.settingsHash['fieldMapping']?.[workflowTemplateName].length === 0) {
    return (
      <Alert center variant='outlined' severity='warning' body='You must configure Field Mapping and save first' open={true} />
    );
  }

  // block access to Execution tab if there are unsaved changes in credentials, destinations or field mappings
  if (state.modified) {
    return (
      <Alert center variant='outlined' severity='warning' body='You must save your pending changes first' open={true} />
    );
  }

  // block access to Execution tab if it fails to provision workflow for this connector
  if (state.provisionSolutionInstanceError) {
    return (
      <Alert center variant='outlined' severity='error' body='Failed to provision your instance. Please contact admin.' open={true} />
    );
  }

  // block access to Execution tab if there's no workflow that matches the name stored in workflowTemplateName
  if (!state.settingsHash['solutionInstances'] || !state.settingsHash['solutionInstances']?.find((s: SolutionInstance) => s.solution_instance_name === workflowTemplateName)) {
    return (
      <Alert center variant='outlined' severity='warning' body='Please wait while we are provisioning your instance' open={true} />
    );
  }

  return (
    <WorkflowExecution
      tableName='execution'
      workflowTemplateName={workflowTemplateName}
      taskRerunEnabled={!isBilling} // Revenue allow task reruns in their workflow template, but we (Billing) do not
      onViewSummaryClickHandler={isBilling ? onViewSummaryClickHandler : onDownloadClickHandler}
    />
  );
};
