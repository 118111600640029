import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { Button, Grid, Modal, ModalActions, ModalTitle, MultiOrgPicker } from '@platform-ui/design-system';
import React, { FC, useState } from 'react';
import Connect from '../../../../../Connect/Connect';
import { useStoreDispatch } from '../../../../../Store';
import { useToast } from '../../../../../ToastProvider';
import { AddModalProps, DestRowData } from './types';

export const AddModal: FC<AddModalProps> = ({
  orgOptions,
  openAddModal,
  setOpenAddModal,
  rows,
  setRows
}: AddModalProps) => {
  const dispatch = useStoreDispatch();
  const [isAdding, setIsAdding] = useState(false);
  const [orgs, setOrgs] = useState([]);
  const [customerId, setCustomerId] = useState('');
  const [destSystem, setDestSystem] = useState('');
  const [destName, setDestName] = useState('');
  const { setToast } = useToast();

  return (
    <Modal
      id='add-destination-modal'
      open={openAddModal}
      disableBackdropClick={true}
      header={<ModalTitle dsOnClose={() => setOpenAddModal(false)}>Add a Destination</ModalTitle>}
      dsOnClose={() => setOpenAddModal(false)}
      body={
        <Grid container direction='column'>
          {
            orgOptions.length > 0 &&
            <Grid item>
              <FormControl
                variant="outlined"
                fullWidth
                required
              >
                <InputLabel variant="outlined">Organization</InputLabel>
                <MultiOrgPicker
                  multiple
                  disableCascadingDown
                  required
                  options={orgOptions.map((orgOption: { id: string; name: string; parentOrgId: string | null }) =>
                    ({ organizationId: orgOption.id, organizationName: orgOption.name, parentId: orgOption.parentOrgId }))}
                  orgLabels={orgs.map(org => ({ organizationId: org.id, organizationName: org.name, cascadingDown: false, excluded: false }))}
                  userOrgIds={orgOptions.filter(orgOption => rows.every(row => row.orgId !== orgOption.id)).map(orgOption => orgOption.id)}
                  onChange={(orgLabels) => setOrgs(orgLabels.map(orgLabel => ({ id: orgLabel.organizationId, name: orgLabel.organizationName })))}
                  showAllOrgs
                  exactMatchUserOrg
                />
              </FormControl>
            </Grid>
          }
          <Grid item>
            <TextField
              fullWidth
              required
              placeholder='Enter Customer ID'
              onChange={(e) => setCustomerId(e.target.value)}
              value={customerId}
              label='Customer ID'
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              required
              placeholder='Enter Destination Name'
              onChange={(e) => setDestName(e.target.value)}
              value={destName}
              label='Destination Name'
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              required
              placeholder='Enter Destination System'
              onChange={(e) => setDestSystem(e.target.value)}
              value={destSystem}
              label='Destination System'
            />
          </Grid>
        </Grid>
      }
      footer={
        <ModalActions>
          <Button variant='outlined' dsOnClick={() => setOpenAddModal(false)} body='Cancel' />
          <Button
            disabled={isAdding || !customerId || !destName || !destSystem || (orgOptions.length > 0 && orgs.length <= 0)}
            body='Add'
            dsOnClick={async () => {
              setIsAdding(true);

              try {
                const addedRows = orgOptions.length > 0 ? orgs.map(org => ({ orgId: org.id, orgName: org.name, customerId, destSystem, destName }))
                  : [{ customerId, destSystem, destName }];
                const found = addedRows.some(addedRow => rows.findIndex(
                  (row: DestRowData) =>
                    (row.orgId === addedRow.orgId)) !== -1);
                if (found) {
                  setToast({
                    message: 'Duplicate organization(s) found in the destination table! Please deselect them in the modal.',
                    severity: 'error',
                    keyRender: Date.now()
                  });
                  setIsAdding(false);
                  return;
                }
                const clone = [...rows, ...addedRows];
                setRows(clone);
                dispatch({
                  type: 'destination',
                  payload: clone
                });
              } catch(error) {
                Connect.log(error);
              }

              setIsAdding(false);
              setCustomerId('');
              setDestName('');
              setDestSystem('');
              setOrgs([]);
              setOpenAddModal(false);
            }}
          />
        </ModalActions>
      }
    />
  );
};