import { Alert, Spinner } from '@platform-ui/design-system';
import React from 'react';
import { IADState } from '../../../IntegrationApps/IntegrationAppDetails/state';
import { useStoreState } from '../../../Store';
import { useFlipperHook } from '../../../Tables/helpers';
import { useIdentity } from '../../../IdentityProvider';
import BasicTemplate from '../../../BasicTemplate/BasicTemplate';

export const FieldMapping = (props: { fieldGroups: any[], tabName: string }) => {
  const state = useStoreState() as IADState;
  const [sapFeatureEnabled, sapFeatureLoading] = useFlipperHook('enable_sap_connector');
  const { hasPermission } = useIdentity();
  const isConnectorEnabled = hasPermission('permission.ZuoraSAPConnector');

  if (sapFeatureLoading) {
    return <Spinner />;
  }

  // block access to Field Mapping tab if connector is not enabled
  if (!sapFeatureEnabled && !isConnectorEnabled) {
    return (
      <Alert open center variant='outlined' severity='warning' body={`Missing required permission 'permission.ZuoraSAPConnector'`} />
    );
  }

  // block access to Field Mapping tab if integration is not enabled in Authentication tab
  if (!state.active) {
    return (
      <Alert center variant='outlined' severity='warning' body='You must enable integration in Authentication tab first' open={true} />
    );
  }

  // block access to Field Mapping tab if credentials are not set and saved in Authentication tab
  if (!state.saved && !state.settingsHash['authentication']) {
    return (
      <Alert center variant='outlined' severity='warning' body='You must configure Authentication and save first' open={true} />
    );
  }

  return (
    <BasicTemplate
      id='sap-field-mapping'
      showHeader={false}
      fieldGroups={props.fieldGroups}
      tabName={props.tabName}
    />
  );
}